/* @tailwind base; */

.tw-static {
  position: static !important
}

.tw-absolute {
  position: absolute !important
}

.tw-relative {
  position: relative !important
}

.tw-sticky {
  position: -webkit-sticky !important;
  position: sticky !important
}

.tw-bottom-0 {
  bottom: 0px !important
}

.tw-top-0 {
  top: 0px !important
}

.tw-z-0 {
  z-index: 0 !important
}

.tw-z-\[1\] {
  z-index: 1 !important
}

.tw-m-2 {
  margin: 0.5rem !important
}

.tw-mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important
}

.tw-mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.tw-mb-0 {
  margin-bottom: 0px !important
}

.tw-mb-1 {
  margin-bottom: 0.25rem !important
}

.tw-mb-1\.5 {
  margin-bottom: 0.375rem !important
}

.tw-mb-2 {
  margin-bottom: 0.5rem !important
}

.tw-mb-3 {
  margin-bottom: 0.75rem !important
}

.tw-mb-4 {
  margin-bottom: 1rem !important
}

.tw-mb-6 {
  margin-bottom: 1.5rem !important
}

.tw-mb-8 {
  margin-bottom: 2rem !important
}

.tw-me-2 {
  margin-inline-end: 0.5rem !important
}

.tw-me-3 {
  margin-inline-end: 0.75rem !important
}

.tw-ms-2 {
  margin-inline-start: 0.5rem !important
}

.tw-ms-auto {
  margin-inline-start: auto !important
}

.tw-mt-0 {
  margin-top: 0px !important
}

.tw-mt-2 {
  margin-top: 0.5rem !important
}

.tw-mt-3 {
  margin-top: 0.75rem !important
}

.tw-mt-6 {
  margin-top: 1.5rem !important
}

.tw-mt-8 {
  margin-top: 2rem !important
}

.tw-block {
  display: block !important
}

.tw-inline-block {
  display: inline-block !important
}

.tw-flex {
  display: flex !important
}

.tw-grid {
  display: grid !important
}

.tw-hidden {
  display: none !important
}

.tw-h-1 {
  height: 0.25rem !important
}

.tw-h-1\.5 {
  height: 0.375rem !important
}

.tw-h-14 {
  height: 3.5rem !important
}

.tw-h-4 {
  height: 1rem !important
}

.tw-h-9 {
  height: 2.25rem !important
}

.tw-h-\[10\%\] {
  height: 10% !important
}

.tw-h-\[42px\] {
  height: 42px !important
}

.tw-h-\[5\%\] {
  height: 5% !important
}

.tw-h-\[51px\] {
  height: 51px !important
}

.tw-h-\[85\%\] {
  height: 85% !important
}

.tw-h-fit {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important
}

.tw-h-full {
  height: 100% !important
}

.tw-h-screen {
  height: 100vh !important
}

.tw-max-h-full {
  max-height: 100% !important
}

.tw-min-h-5 {
  min-height: 1.25rem !important
}

.tw-w-10 {
  width: 2.5rem !important
}

.tw-w-14 {
  width: 3.5rem !important
}

.tw-w-4 {
  width: 1rem !important
}

.tw-w-9 {
  width: 2.25rem !important
}

.tw-w-\[60px\] {
  width: 60px !important
}

.tw-w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important
}

.tw-w-full {
  width: 100% !important
}

.tw-min-w-5 {
  min-width: 1.25rem !important
}

.tw-max-w-3xl {
  max-width: 48rem !important
}

.tw-max-w-80 {
  max-width: 20rem !important
}

.tw-max-w-96 {
  max-width: 24rem !important
}

.tw-max-w-lg {
  max-width: 32rem !important
}

.tw-flex-grow {
  flex-grow: 1 !important
}

.tw-cursor-pointer {
  cursor: pointer !important
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important
}

.tw-flex-col {
  flex-direction: column !important
}

.tw-flex-wrap {
  flex-wrap: wrap !important
}

.tw-flex-nowrap {
  flex-wrap: nowrap !important
}

.tw-items-center {
  align-items: center !important
}

.tw-justify-start {
  justify-content: flex-start !important
}

.tw-justify-end {
  justify-content: flex-end !important
}

.tw-justify-center {
  justify-content: center !important
}

.tw-justify-between {
  justify-content: space-between !important
}

.tw-gap-1 {
  gap: 0.25rem !important
}

.tw-gap-4 {
  gap: 1rem !important
}

.tw-gap-5 {
  gap: 1.25rem !important
}

.tw-justify-self-end {
  justify-self: end !important
}

.tw-justify-self-center {
  justify-self: center !important
}

.tw-overflow-auto {
  overflow: auto !important
}

.tw-overflow-hidden {
  overflow: hidden !important
}

.tw-overflow-y-auto {
  overflow-y: auto !important
}

.tw-rounded {
  border-radius: 0.25rem !important
}

.tw-rounded-lg {
  border-radius: 0.5rem !important
}

.tw-rounded-md {
  border-radius: 0.375rem !important
}

.tw-rounded-sm {
  border-radius: 0.125rem !important
}

.tw-rounded-xl {
  border-radius: 0.75rem !important
}

.tw-border {
  border-width: 1px !important
}

.tw-border-b {
  border-bottom-width: 1px !important
}

.tw-border-t {
  border-top-width: 1px !important
}

.tw-border-none {
  border-style: none !important
}

.tw-border-\[\#026E63\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(2 110 99 / var(--tw-border-opacity)) !important
}

.tw-border-\[\#CDCDCD\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(205 205 205 / var(--tw-border-opacity)) !important
}

.tw-border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important
}

.tw-border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important
}

.tw-border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important
}

.tw-bg-\[\#026e63\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(2 110 99 / var(--tw-bg-opacity)) !important
}

.tw-bg-\[\#428B8A\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(66 139 138 / var(--tw-bg-opacity)) !important
}

.tw-bg-\[\#E5C2B2\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 194 178 / var(--tw-bg-opacity)) !important
}

.tw-bg-\[\#F6F6F6\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity)) !important
}

.tw-bg-\[\#F8F8F8\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity)) !important
}

.tw-bg-\[\#FEFEFE\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 254 254 / var(--tw-bg-opacity)) !important
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important
}

.tw-bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity)) !important
}

.tw-bg-transparent {
  background-color: transparent !important
}

.tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.tw-p-0 {
  padding: 0px !important
}

.tw-p-1 {
  padding: 0.25rem !important
}

.tw-p-10 {
  padding: 2.5rem !important
}

.tw-p-2 {
  padding: 0.5rem !important
}

.tw-p-4 {
  padding: 1rem !important
}

.tw-p-5 {
  padding: 1.25rem !important
}

.tw-p-6 {
  padding: 1.5rem !important
}

.tw-p-8 {
  padding: 2rem !important
}

.tw-p-\[3dvw\] {
  padding: 3dvw !important
}

.tw-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important
}

.tw-px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important
}

.tw-px-\[10dvw\] {
  padding-left: 10dvw !important;
  padding-right: 10dvw !important
}

.tw-px-\[7dvw\] {
  padding-left: 7dvw !important;
  padding-right: 7dvw !important
}

.tw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.tw-py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important
}

.tw-py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important
}

.tw-py-\[12dvh\] {
  padding-top: 12dvh !important;
  padding-bottom: 12dvh !important
}

.tw-pb-10 {
  padding-bottom: 2.5rem !important
}

.tw-pb-4 {
  padding-bottom: 1rem !important
}

.tw-pb-\[7cqh\] {
  padding-bottom: 7cqh !important
}

.tw-pl-3 {
  padding-left: 0.75rem !important
}

.tw-pr-2 {
  padding-right: 0.5rem !important
}

.tw-pr-8 {
  padding-right: 2rem !important
}

.tw-pt-0 {
  padding-top: 0px !important
}

.tw-pt-3 {
  padding-top: 0.75rem !important
}

.tw-pt-\[5dvh\] {
  padding-top: 5dvh !important
}

.tw-pt-\[9dvh\] {
  padding-top: 9dvh !important
}

.tw-text-center {
  text-align: center !important
}

.tw-text-justify {
  text-align: justify !important
}

.tw-text-end {
  text-align: end !important
}

.tw-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important
}

.tw-text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important
}

.tw-text-5xl {
  font-size: 3rem !important;
  line-height: 1 !important
}

.tw-text-\[2\.5rem\] {
  font-size: 2.5rem !important
}

.tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important
}

.tw-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important
}

.tw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important
}

.tw-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important
}

.tw-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important
}

.tw-font-bold {
  font-weight: 700 !important
}

.tw-font-medium {
  font-weight: 500 !important
}

.tw-font-normal {
  font-weight: 400 !important
}

.tw-font-semibold {
  font-weight: 600 !important
}

.tw-uppercase {
  text-transform: uppercase !important
}

.tw-leading-5 {
  line-height: 1.25rem !important
}

.tw-leading-6 {
  line-height: 1.5rem !important
}

.tw-leading-8 {
  line-height: 2rem !important
}

.tw-leading-\[0\.875rem\] {
  line-height: 0.875rem !important
}

.tw-leading-none {
  line-height: 1 !important
}

.tw-leading-normal {
  line-height: 1.5 !important
}

.tw-leading-tight {
  line-height: 1.25 !important
}

.tw-text-\[\#006E7E\] {
  --tw-text-opacity: 1 !important;
  color: rgb(0 110 126 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#026E63\] {
  --tw-text-opacity: 1 !important;
  color: rgb(2 110 99 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#292929\] {
  --tw-text-opacity: 1 !important;
  color: rgb(41 41 41 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#3F414E\] {
  --tw-text-opacity: 1 !important;
  color: rgb(63 65 78 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#525252\] {
  --tw-text-opacity: 1 !important;
  color: rgb(82 82 82 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#545454\] {
  --tw-text-opacity: 1 !important;
  color: rgb(84 84 84 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#666666\] {
  --tw-text-opacity: 1 !important;
  color: rgb(102 102 102 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#7B7B7B\] {
  --tw-text-opacity: 1 !important;
  color: rgb(123 123 123 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#919094\] {
  --tw-text-opacity: 1 !important;
  color: rgb(145 144 148 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#A1A4B2\] {
  --tw-text-opacity: 1 !important;
  color: rgb(161 164 178 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#B2B2B2\] {
  --tw-text-opacity: 1 !important;
  color: rgb(178 178 178 / var(--tw-text-opacity)) !important
}

.tw-text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important
}

.tw-text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important
}

.tw-text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity)) !important
}

.tw-text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important
}

.tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.tw-underline {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-transition-all {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important
}

.tw-duration-300 {
  transition-duration: 300ms !important
}

.tw-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important
}

.hover\:tw-bg-\[\#026e63\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(2 110 99 / var(--tw-bg-opacity)) !important
}

.disabled\:tw-cursor-not-allowed:disabled {
  cursor: not-allowed !important
}

.disabled\:tw-bg-\[\#428b8ab5\]:disabled {
  background-color: #428b8ab5 !important
}

.disabled\:tw-opacity-50:disabled {
  opacity: 0.5 !important
}

@media (min-width: 640px) {
  .sm\:tw-block {
    display: block !important
  }

  .sm\:tw-hidden {
    display: none !important
  }

  .sm\:tw-h-full {
    height: 100% !important
  }

  .sm\:tw-w-1\/4 {
    width: 25% !important
  }

  .sm\:tw-w-20 {
    width: 5rem !important
  }

  .sm\:tw-w-auto {
    width: auto !important
  }

  .sm\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
  }

  .sm\:tw-flex-col {
    flex-direction: column !important
  }

  .sm\:tw-flex-nowrap {
    flex-wrap: nowrap !important
  }

  .sm\:tw-justify-between {
    justify-content: space-between !important
  }

  .sm\:tw-bg-transparent {
    background-color: transparent !important
  }

  .sm\:tw-p-0 {
    padding: 0px !important
  }

  .sm\:tw-pb-0 {
    padding-bottom: 0px !important
  }

  .sm\:tw-pt-\[6cqh\] {
    padding-top: 6cqh !important
  }
}

@media (min-width: 768px) {
  .md\:-tw-mb-4 {
    margin-bottom: -1rem !important
  }

  .md\:tw-h-\[60px\] {
    height: 60px !important
  }

  .md\:tw-w-\[80px\] {
    width: 80px !important
  }

  .md\:tw-w-auto {
    width: auto !important
  }

  .md\:tw-max-w-96 {
    max-width: 24rem !important
  }

  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
  }

  .md\:tw-flex-nowrap {
    flex-wrap: nowrap !important
  }

  .md\:tw-justify-end {
    justify-content: flex-end !important
  }

  .md\:tw-rounded-none {
    border-radius: 0px !important
  }

  .md\:tw-p-0 {
    padding: 0px !important
  }

  .md\:tw-p-8 {
    padding: 2rem !important
  }

  .md\:tw-p-\[5dvw\] {
    padding: 5dvw !important
  }

  .md\:tw-px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }

  .md\:tw-pt-6 {
    padding-top: 1.5rem !important
  }

  .md\:tw-pt-\[20dvh\] {
    padding-top: 20dvh !important
  }

  .md\:tw-text-9xl {
    font-size: 8rem !important;
    line-height: 1 !important
  }
}